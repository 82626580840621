import { configHeaders } from "utils/request-config"

export interface Payment {
    username: string
    message : string
    amount  : number
    currency?: string
    additional_details?: any
}

const connectApi = (user) => {
    const headers = configHeaders(process.env.REACT_APP_CONNECT_API_SECRET_KEY || "")
    const api     = `${process.env.REACT_APP_CORE_API_URL}/connect/api/v3`

    const createToken = async (payment: Payment) => {
        return await fetch(`${api}/payment-token/create`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                username          : payment.username,
                message           : payment.message,
                total             : payment.amount,
                name              : `${user?.firstName} ${user?.lastName}`,
                email             : user?.email,
                mobile_number     : user?.mobile_number || {},
                additional_details: payment.additional_details || {}
            })
        }).then(
            res => res.json()
        )
    }

    const getToken = async (token: string, callback: any) => {
        await new Promise(r => setTimeout(r, 1000));
        fetch(
            `${api}/payment-token/get/${token}`, {headers}
        ).then(
            res => res.json()
        ).then(
            json => callback && callback(json)
        )
    }

    return {createToken, getToken}
}

export default connectApi