import ButtonLoader from "app/components/ButtonLoader";
import InputField from "app/components/InputField";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import MasterPage from "../MasterPage";
import bankVerificationSaga from "./saga";
import { selectBankVerification } from "./selectors";
import { bankVerificationActions as actions, bankVerificationActions, sliceKey as key, reducer } from "./slice";

// Assets
import ErrorModal from "app/components/Modal/ErrorModal";
import SuccessModal from "app/components/Modal/SuccessModal";
import bg from 'assets/img/background/justpayto-philippines-payments-1.jpg';
import logo from 'assets/img/landing-page/jpt-logo.svg';
import { useHistory } from "react-router-dom";
import useEmailVerifier from "utils/emailVerifier";

const BankVerification = memo(() => {
    useInjectReducer({ key, reducer });
    useInjectSaga({ key, saga: bankVerificationSaga });

    const emailVerify      = useEmailVerifier();
    const history          = useHistory();
    const dispatch         = useDispatch();
    const bankVerification = useSelector(selectBankVerification)

    const required_field = 'This field is required.'
    const invalid_amount = "Check your Bank Account or Digital Wallet. Look for a deposit made by JustPayto Philippines Corporation. Enter the EXACT amount."

    const [username_email, setUsernameEmail] = useState('');
    const [amount, setAmount]               = useState('');
    const [error, setError]                 = useState('');
    const [successOpen, setSuccessOpen]     = useState(false);
    const [errorOpen, setErrorOpen]         = useState(false);

    useEffect(() => {
        if (bankVerification?.confirmDepositError) {
            setError(bankVerification?.confirmDepositError == "Amount does not match"? invalid_amount: bankVerification?.confirmDepositError);
            setErrorOpen(true);
            dispatch(actions.confirmDepositError(''));
        }
    }, [bankVerification.confirmDepositError])
    useEffect(() => {
        if (bankVerification.confirmDepositResults) {
            setError('')
            setSuccessOpen(true)
        }   
    }, [bankVerification.confirmDepositResults])

    const verify = () => {
        setError('')
        if (!username_email.includes('@')) {
            dispatch(bankVerificationActions.confirmDeposit({username_email, amount: +amount}))
        } else {
            emailVerify(username_email, (valid, error) => {
                if (valid) {
                    dispatch(bankVerificationActions.confirmDeposit({username_email, amount: +amount}))
                } else {
                    setError(error)
                }
            })
        }
    }

    return (
        <MasterPage title="Bank Verification" backgroundImage={bg} hasHeader>
        <section className="action-box-wrapper">
            <section className="login action-box content-box small">
                <img className="logo" src={logo} alt={process.env.REACT_APP_NAME}/>
                <h3>
                    Check your bank account or e-wallet.<br/>
                    Look for a deposit made by<br/>
                    JustPayto Philippines Corporation.
                </h3>
                <div jpt-form="login" className="pseudo-form bank-verification">
                    <InputField
                        value       = {username_email}
                        type        = "text"
                        name        = "email"
                        placeholder = "Your Username or Email"
                        onChange    = {e => setUsernameEmail(e.target.value)}
                        style       = {{inputContainer: 'icon icon-email ignore'}}
                    />
                    <InputField
                        value       = {amount}
                        type        = 'number'
                        name        = 'amount'
                        placeholder = 'Amount'
                        onChange    = {e => setAmount(e.target.value)}
                        style       = {{inputContainer: 'icon icon-amount ignore'}}
                    />
                    <small className="instructions">Enter the EXACT amount deposited to your account</small>
                    <ButtonLoader submit hasLoader
                        loading = {bankVerification.confirmingDeposit}
                        name  = 'request_invite'
                        style = 'filled blue'
                        text  = 'Verify'
                        onClick = {verify}
                    />
                </div>
            </section>
        </section>
        <SuccessModal
            state   = {[successOpen, setSuccessOpen]}
            message = "Congratulation!. You can now accept any kind of payment anywhere, anytime. Please check your email for more details"
            onClose = {() => history.push('/login')}
        />
        <ErrorModal closeButton
            state   = {[errorOpen, setErrorOpen]}
            title   = {error == invalid_amount? "Try Again": undefined}
            message = {error}
        />
        </MasterPage>
    )
})

export default BankVerification;