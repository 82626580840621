import React from 'react';
import { formatDatetime, numberFormat } from 'utils';
import { fulfillment_methods } from 'utils/options';

const TransactionDetails = ({transaction}) => {

    let is_petron   = transaction?.is_petron,
        is_business = ['business', 'client'].includes(transaction?.receiver_details?.account_type),
        is_donation = transaction?.is_donation,
        is_client   = transaction?.is_partner,
        is_p2p      = transaction?.is_p2p;

    const sender = is_business || is_client? 'Customer': 'Sender'
    const {
        sender_details,
        amount_details,
        source_of_funds,
        additional_details: {
            order_details
        }
    } = transaction;

    const max_amount_length = numberFormat(transaction?.amount_sent).length
    const amountFormat = (amount) => {
        const number       = numberFormat(amount)
        const no_of_spaces = max_amount_length - number.length + 1
        const spaces = new Array(no_of_spaces).join("\u00a0\u00a0")
        return spaces + number
    }
    const formatCurrency = (amount) => (
        amount_details?.currency == 'PHP'?
        `₱ ${amountFormat(amount)}`:
        `${amountFormat(amount)} ${amount_details?.currency}`
    )
    let amount           = formatCurrency(+(transaction?.amount_sent || 0)),
        other_charges    = formatCurrency(+(transaction?.other_charges || 0)),
        system_fee       = formatCurrency(+(amount_details?.fees?.system || 0)),
        sending_fee      = formatCurrency(+(amount_details?.fees?.sending?.fee || 0)),
        receiving_fee    = formatCurrency(+(amount_details?.fees?.receiving?.fee || 0)),
        fullfillment_fee = formatCurrency(
            +(amount_details?.fees?.delivery?.actual_fee || 0) +
            +(amount_details?.fees?.delivery?.system || 0)
        );
    
    let sender_name    = is_business && !is_donation? "payer": "sender",
        charge_fees_to = `- charge to ${is_client || transaction?.charge_fees_to == 'sender'? sender_name : 'recipient'}`;

    return (
        <table>
            <tbody>
                <tr>
                    <td>{sender} Name:</td>
                    <td>{sender_details?.name}</td>
                </tr>
                <tr>
                    <td>{sender} Email:</td>
                    <td>{sender_details?.email}</td>
                </tr>
                <tr>
                    <td>{sender} Phone:</td>
                    <td>{
                        transaction?.sender_mobile_number
                    }</td>
                </tr>
                {
                    is_business &&
                    !is_donation &&
                    !is_client && <tr>
                        <td>Fulfillment Method:</td>
                        <td>{is_petron ? "Regular Payment": fulfillment_methods[order_details?.method || '']}</td>
                    </tr>
                }
                {order_details?.method == "pick_up" && <tr>
                    <td>Pickup Time:</td>
                    <td>{order_details?.pickup_time}</td>
                </tr>}
                {is_business && order_details?.method == "delivery" && <tr>
                    <td>Delivery Address:</td>
                    <td style={{whiteSpace: 'normal'}}>{order_details?.shipping_address}</td>
                </tr>}
                <tr className="spacer"></tr>
                {
                    'autosweeprfid' == transaction?.receiver && <tr>
                        <td>Plate/Account No.:</td>
                        <td>{transaction?.additional_details?.client_details?.autosweeprfid?.plate_number}</td>
                    </tr>
                }
                {
                    'easytriprfid' == transaction?.receiver && <tr>
                        <td>Plate/Account No.:</td>
                        <td>{
                            transaction?.additional_details?.client_details?.plate_number ||
                            transaction?.additional_details?.client_details?.account_no
                        }</td>
                    </tr>
                }
                {
                    'beep' == transaction?.receiver && <tr>
                        <td>Card No.:</td>
                        <td>{
                            transaction?.additional_details?.client_details?.beep?.card_number ||
                            transaction?.additional_details?.client_details?.beep?.verify_account?.payload?.card_number ||
                            transaction?.additional_details?.client_details?.beep?.verify_account?.payload?.Can
                        }</td>
                    </tr>
                }
                {
                    'bayadcenter' == transaction?.receiver && <tr>
                        <td>Account No.:</td>
                        <td>{
                            transaction?.additional_details?.client_details?.account_number ||
                            transaction?.additional_details?.client_details?.other_details?.account_no ||
                            transaction?.additional_details?.client_details?.other_details?.account_number ||
                            transaction?.additional_details?.client_details?.bayadcenter?.verify_account?.result?.data?.account
                        }</td>
                    </tr>
                }
                {
                    is_p2p && <tr>
                        <td>Message/Instructions:</td>
                        <td style={{whiteSpace: 'normal'}}>{transaction?.sender_details?.message}</td>
                    </tr>
                }
                <tr className="spacer"></tr>
                <tr>
                    <td>Transaction Date:</td>
                    <td>{formatDatetime(transaction?.updated_at, 'MMMM DD, YYYY (hh:mm A)')}</td>
                </tr>
                <tr>
                    <td>Transction Number:</td>
                    <td>{transaction?.payment_id}</td>
                </tr>
                <tr className="spacer"></tr>
                <tr>
                    <td>{
                        ['autosweeprfid', 'easytriprfid', 'beep'].includes(transaction?.receiver || '')?
                        'Amount Reloaded:':
                        'Amount Paid:'
                    }</td>
                    <td>
                        {amount} (via {source_of_funds?.service_name})</td>
                </tr>
                <tr>
                    <td>Fees:</td>
                    <td>
                        <span>{system_fee}</span> (system fee) {charge_fees_to}
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <span>{sending_fee}</span> (sending processing fee) {charge_fees_to}
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <span>{receiving_fee}</span> (receiving processing fee) {charge_fees_to}
                    </td>
                </tr>
                {
                    is_business &&
                    !is_donation &&
                    !is_client && <tr>
                        <td></td>
                        <td>
                            <span>{fullfillment_fee}</span> (Fulfillment fee) - charge to {sender_name}
                        </td>
                    </tr>
                }
                <tr>
                    <td></td>
                    <td>
                        <span>{other_charges}</span> (Other Fee) - charge to {sender_name}
                    </td>
                </tr>
                <tr className="spacer"></tr>
                <tr>
                    <td>Total Amount:</td>
                    <td>{formatCurrency(
                        +(transaction.payout_amount || 0) + +(
                            amount_details?.witholding_tax?.fee ||
                            amount_details?.fees?.witholding_tax?.fee || 0
                        )
                    )}</td>
                </tr>
                <tr>
                    <td>Withholding Tax:</td>
                    <td>{formatCurrency(+(
                        amount_details?.witholding_tax?.fee ||
                        amount_details?.fees?.witholding_tax?.fee || 0
                    ))}</td>
                </tr>
                <tr>
                    <td>You Receive (after WTax):</td>
                    <td>{formatCurrency(+(transaction.payout_amount || 0))}</td>
                </tr>
            </tbody>
        </table>
    )
}

export default TransactionDetails;