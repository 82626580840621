export const clients = [
    'autosweeprfid',
    'bayadcenter',
    'beep',
    'easytriprfid'
]

export const business_types = {
    "individuals_online_sellers": "Individuals & Online Seller",
    "sole_proprietorship"       : "Sole Proprietorship",
    "partnerships"              : "Partnership",
    "corporations"              : "Corporation",
    "non_profit_organizations"  : "Government/NGO",
}

export const busines_document_names = {
    "individuals_online_sellers": {
        "proof_of_billing"     : "Proof of Billing",
        "photo_of_an_invoice"  : "Photo of an Invoice",
        "approved_goverment_id": "Approved Government ID",
        "dti_certificate"      : "DTI Certificate",
    },
    "sole_proprietorship": {
        "bir_certificate_of_registration": "BIR Certificate of Registration",
        "photo_of_an_invoice"            : "Photo of an Invoice",
        "approved_goverment_id"          : "Approved Government ID",
        "dti_certificate"                : "DTI Certificate",
    },
    "partnerships": {
        "sec_certificate"          : "SEC Certificate",
        "secretarys_certificate"   : "Secretary's Certificate",
        "approved_goverment_id"    : "Approved Government ID",
        "articles_of_incorporation": "Articles of Incorporation"
    },
    "corporations": {
        "sec_certificate"          : "SEC Certificate",
        "secretarys_certificate"   : "Secretary's Certificate",
        "approved_goverment_id"    : "Approved Government ID",
        "articles_of_incorporation": "Articles of Incorporation"
    },
    "non_profit_organizations": {
        "certificate_of_registration"    : "Certificate of Registration",
        "secretarys_certificate"         : "Secretary's Certificate",
        "approved_goverment_id"          : "Approved Government ID",
        "bir_certificate_of_registration": "BIR Certificate of Registration"
    },
}

export const business_categories = {
    "airlines": {
        name   : "Airlines",
        options: [
            "Passenger", "Cargo"
        ],
    },
    "collectibles": {
        name   : "Arts, Crafts and Collectibles",
        options: [
            "Art", "Camera", "Digital Art", "Music Store", "Fabrics", "Papers", "Vintage"
        ],
    },
    "clothing": {
        name   : "Clothing, Accessories and Shoes",
        options: [
            "Men", "Women", "Kids", "Toddlers", "Babies"
        ],
    },
    "electronics": {
        name   : "Computer, Electronics and Services",
        options: [
            "Audio", "Consumer", "Home Automation", "Media Players", "Games Appliances", "Computers", "Mobile Phones", "TV"
        ],
    },
    "entertainment": {
        name   : "Entertainment and Media",
        options: [
            "Film", "Print", "Movies", "TV Shows", "Radio Shows", "News", "Music", 
        ],
    },
    "finance": {
        name   : "Financial Services",
        options: [
            "Accounting", "Banking", "Collection", "Insurance", "Investment", "Lending", "Payments", "Settlement", 
        ],
    },
    "food_drinks": {
        name   : "Food & Drinks Retail and Services",
        options: [
            "Dairy", "Manufacturers", "Restaurants", "Catering", "Coffee Shop", "Liquor"
        ],
    },
    "personal_care": {
        name   : "Health and Personal Care",
        options: [
            "Facial", "Baby", "Bath & Body", "Cosmetics", "Hygiene", "Hair", "Spa"
        ],
    },
    "lodging": {
        name   : "Lodging",
        options: [
            "Hotels", "Motels", "Resorts", "Condominium", "Apartments"
        ],
    },
    "others": "Others",
    "professional_services": {
        name   : "Professional Services",
        options: [
            "Accountant", "Architect", "Consultant", "Engineer", "Financial Planner", "IT", "Lawyer", "Physician", "Training"
        ],
    },
    "real_estate": {
        name   : "Real Estate",
        options: ["Residencial", "Commercial", "Industrial", "Land", "Rent", "Construction"],
    },
    "retail": {
        name   : "Retail",
        options: [
            "Online Retail Sales", "Vehical", "Fuel", "Furniture", "Department Store Supermarket"
        ],
    },
    "education": {
        name   : "Schools and Education",
        options: [
            "Pre-School", "Grade School", "High School", "College", "Grad School", "Vocational", "Homeschool", "Online", 
        ],
    },
    "technology": {
        name   : "Technology Products and Services",
        options: [
            "IT Service", "Infrastructure", "Network", "Servers", "Database", "Support", "Computer Repair", "Storage", "VoIP"
        ],
    },
    "telecommunications": {
        name   : "Telecommunications",
        options: [
            "Email", "Fax", "Instant Messaging", "Radio", "Telephony", "Telegraphy", "Broadcasting", "Video Conferencing", 
        ],
    },
    "travel": {
        name   : "Travel",
        options: [
            "Business", "Tour", "Adventure"
        ],
    },
    "transportation": {
        name   : "Transportation services",
        options: [
            "Bus", "Rail", "Passenger Ship", "Motorcycle Taxi", "Carpool", "Taxicab Transport Hub"
        ],
    },
    "utilities": {
        name   : "Utilities",
        options: [
            "Electricity", "Water", "Gas", "Office", "Oil", "Garbage"
        ],
    },
}

export const social_medias = {
    "facebook" : "Facebook",
    "twitter"  : "X",
    "instagram": "Instagram",
    "linkedin" : "LinkedIn",
}

export const depository_account_categories = {
    bank    : "Bank Account",
    e_wallet: "E-Wallet",
    otc     : "Over-the-Counter / Cash Pick-up"
}

export const depository_accounts = {
    bank: {
        aub             : "Asia United Bank (AUB)",
        bdo             : "Banco De Oro (BDO)",
        boc             : "Bank Of Commerce",
        bpi             : "Bank of the Philippine Islands (BPI)",
        bpisaving       : "BPI Family Savings",
        chinabank       : "Chinabank",
        chinabanksavings: "Chinabank Savings",
        citibank        : "Citibank",
        dbp             : "Development Bank of the Philippines (DBP)",
        equicom         : "Equicom Saving Bank",
        eastwestbank    : "Eastwest Bank",
        hsbc            : "HSBC",
        landbank        : "Landbank",
        metrobank       : "Metrobank",
        maybank         : "Maybank",
        // malaya          : "Malaya Bank",
        pnb             : "Philippine National Bank",
        psb             : "Philippine Savings Bank",
        pvb             : "Philippine Veterans Bank",
        rcbc            : "Rizal Commercial Banking Corporation (RCBC)",
        rcbcsavings     : "RCBC Savings",
        sterling        : "Sterling Bank",
        security        : "Security Bank",
        unionbank       : "Unionbank",
        ucpb            : "United Coconut Planters Bank (UCPB)",
        ucpbsavings     : "UCPB Savings",
    },
    e_wallet: {
        gcash  : "GCash",
        paymaya: "Maya",
        grabpay: "Grabpay"
    },
    otc: {
        cebuana  : "Cebuana Lhullier",
        mlhullier: "M. Lhullier Padala",
        palawan  : "Palawan Pawnshop"
    }
}

export const valid_documents = {
    afp              : "AFP ID",
    dswdcertification: "DSWD Certification",
    nbi              : "NBI Clearance",
    ncwdp            : "NCWDP",
    passport         : "Passport",
    prc              : "PRC ID",
    sss              : "SSS",
    voters           : "Voter's ID",
    driverslicense   : "Driver's License",
    gsis             : "GSIS ID/e-card",
    ofw              : "OFW/OWWA ID",
    postal           : "Postal ID",
    seniorcitizen    : "Senior Citizen Card",
    umid             : "UMID"
}

export const fulfillment_methods = {
    "": "",
    regular         : "Regular Payment",
    instore_purchase: "For Dine-in / On-site",
    pick_up         : "For Pick-up",
    pickup          : "For Pick-up",
    delivery        : "For Delivery"
}

export const payment_methods = {
    "": "",
    mastercard_visa   : {
        "": "",
        credit_card : "Credit Card",
        debit_card  : "Debit Card",
        prepaid_card: "Prepaid Card"
    },
    bank_fund_transfer    : "Bank Transfer",
    online_banking        : "Online Banking",
    online_banking_desktop: "Online Banking Desktop",
    online_banking_mobile : "Online Banking Mobile",
    e_wallet              : "E-wallet",
    over_the_counter      : "Over-the-counter",
    others                : "Others"
}